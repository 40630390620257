import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import "./Sidebar.css";
import SidebarLogo from "../../assets/svg/SidebarLogo.svg";
import { StyledDiv } from "../../views/private/styled";
import { Timeout } from "../../views/public/Login";
import { CustomToolTip } from "../CustomToolTip";

function Navbar(props: any) {
  const [active, setActive] = useState("Dashboard");
  const [hover, setHover] = useState("");
  const [expandedSidebar, setExpandedSidebar] = useState(false);

  const getIcon = (item: any) => {
    return item.title === hover || item.title === active
      ? item.activeIcon
      : item.icon;
  };

  const logoutUser = () => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/jwt/logout`, {
      method: "POST",
      credentials: "include",
      signal: Timeout(7).signal,
    }).then((data) => {
      window.location.reload();
    });
  };

  return (
    <nav className="nav-menu">
      <ul className="nav-menu-items">
        <Link className="sidebarLogo" to="/">
          <img src={SidebarLogo} alt="W360" />
        </Link>
        <div className="all-nav-items">
          {SidebarData.map((item, index) => (
            <CustomToolTip
              key={item.title}
              content={item.title}
              ttId={item.title}
              direction={expandedSidebar ? "" : "right"}
            >
              <li
                onClick={() => setActive(item.title)}
                onMouseEnter={() => setHover(item.title)}
                onMouseLeave={() => setHover("")}
                key={index}
                className={item.cName}
              >
                {item.isExternalPath ? (
                  <a href={item.path} target={item.target}>
                    {getIcon(item)}
                  </a>
                ) : (
                  <Link to={{ pathname: item.path }} target={item.target}>
                    {getIcon(item)}
                  </Link>
                )}
                {expandedSidebar && (
                  <StyledDiv css="padding-left 10px; align-items: center; font-weight: bold">
                    {item.title}
                  </StyledDiv>
                )}
              </li>
            </CustomToolTip>
          ))}
          <StyledDiv onClick={logoutUser} className="logoutIconCss">
            <CustomToolTip
              content={"Log out"}
              ttId={"logout"}
              direction={expandedSidebar ? "" : "right"}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="27"
                viewBox="0 0 24 23"
                fill="none"
                strokeWidth="2.5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
            </CustomToolTip>

            {expandedSidebar && (
              <StyledDiv css="padding-left 10px; align-items: center; font-weight: bold">
                Logout
              </StyledDiv>
            )}
          </StyledDiv>
          <StyledDiv
            className="expandCollapse"
            onClick={() => setExpandedSidebar(!expandedSidebar)}
          >
            <CustomToolTip
              content={expandedSidebar ? "Collapse" : "Expand"}
              ttId={"expandCollapse"}
              direction="right"
            >
              <StyledDiv className="expandCollapse">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d={
                      expandedSidebar
                        ? "M2,3 C2.55228,3 3,3.44772 3,4 L3,12 C3,12.5523 2.55229,13 2,13 C1.44772,13 1,12.5523 1,12 L1,4 C1,3.44772 1.44772,3 2,3 Z M7.29289,4.29289 C7.68342,3.90237 8.31658,3.90237 8.7071,4.29289 C9.09763,4.68342 9.09763,5.31658 8.7071,5.70711 L7.41421,7 L14,7 C14.5523,7 15,7.44772 15,8 C15,8.55228 14.5523,9 14,9 L7.41421,9 L8.7071,10.2929 C9.09763,10.6834 9.09763,11.3166 8.7071,11.7071 C8.31658,12.0976 7.68342,12.0976 7.29289,11.7071 L3.58578,8 L7.29289,4.29289 Z"
                        : "M14,3 C14.5523,3 15,3.44772 15,4 L15,12 C15,12.5523 14.5523,13 14,13 C13.4477,13 13,12.5523 13,12 L13,4 C13,3.44772 13.4477,3 14,3 Z M8.70711,4.29289 L12.4142,8 L8.70711,11.7071 C8.31658,12.0976 7.68342,12.0976 7.2929,11.7071 C6.90237,11.3166 6.90237,10.6834 7.2929,10.2929 L8.58579,9 L2,9 C1.44771,9 1,8.55228 1,8 C1,7.44772 1.44771,7 2,7 L8.58579,7 L7.29289,5.70711 C6.90237,5.31658 6.90237,4.68342 7.29289,4.29289 C7.68342,3.90237 8.31658,3.90237 8.70711,4.29289 Z"
                    }
                  />
                </svg>
                {expandedSidebar && (
                  <StyledDiv css="padding-left 10px; align-items: center; font-weight: bold">
                    Collapse
                  </StyledDiv>
                )}
              </StyledDiv>
            </CustomToolTip>
          </StyledDiv>
        </div>
      </ul>
    </nav>
  );
}

export default Navbar;
