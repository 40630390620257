import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import "../../assets/css/SearchBox.css";
import { useViewer } from "../../viewerContext";
import searchIcon from "../../assets/svg/IkonSearch.svg";

const SearchBoxComponent = ({ availableBandels }) => {
  const [availableKms, setAvailableKms] = useState([]);
  const [availableMeters, setAvailableMeters] = useState([]);
  const { setViewerState } = useViewer();

  const [searchType, setSearchType] = useState("position");

  // Values from sessionStorage
  const [bdl, setBdl] = useState(() => sessionStorage.getItem("bdl") || "");
  const [km, setKm] = useState(() => sessionStorage.getItem("km") || "");
  const [m, setM] = useState(() => sessionStorage.getItem("m") || "");

  useEffect(() => {
    if (bdl && availableBandels[bdl]) {
      setAvailableKms(Object.keys(availableBandels[bdl]));
    }
    if (km && availableBandels[bdl] && availableBandels[bdl][km]) {
      setAvailableMeters(availableBandels[bdl][km]);
    }
    window.addEventListener("beforeunload", () => {
      sessionStorage.clear(); // Clears sessionStorage when the page is to be reloaded or closed
    });
  }, [bdl, km, availableBandels]);

  // Manage the selection in the dropdown Position/Location
  const handleSearchTypeChange = (e) => {
    setSearchType(e.target.value);
  };

  // When the user selects a bandel, update the available kilometers
  const handleBdlChange = (e) => {
    const selectedBdl = e.target.value;
    setBdl(selectedBdl);
    sessionStorage.setItem("bdl", selectedBdl); // Save bdl to sessionStorage

    if (availableBandels[selectedBdl]) {
      setAvailableKms(Object.keys(availableBandels[selectedBdl]));
    } else {
      setAvailableKms([]);
    }
  };

  // When the user selects a km, update the available meter
  const handleKmChange = (e) => {
    const selectedKm = e.target.value;
    setKm(selectedKm);
    sessionStorage.setItem("km", selectedKm); // Save to km sessionStorage

    if (availableBandels[bdl] && availableBandels[bdl][selectedKm]) {
      setAvailableMeters(availableBandels[bdl][selectedKm]);
    } else {
      setAvailableMeters([]);
    }
  };

  const handleMChange = (e) => {
    const selectedMeter = e.target.value;
    setM(selectedMeter);
    sessionStorage.setItem("m", selectedMeter); // Save to m sessionStorage
  };

  // To be able to press enter
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevents the page from reloading

      const selectedMeter = availableMeters.find(
        ({ meter }) => meter === parseInt(m)
      );

      if (selectedMeter) {
        setViewerState({
          type: "UPDATE_FROM_SEARCHBOX",
          payload: { id: selectedMeter.index },
        });
      }
    }
  };

  const handleSearch = (e) => {
    e.preventDefault(); // Prevents the page from reloading

    // Find the object that matches the selected meter value
    const selectedMeter = availableMeters.find(
      ({ meter }) => meter === parseInt(m)
    );

    if (selectedMeter) {
      setViewerState({
        type: "UPDATE_FROM_SEARCHBOX",
        payload: { id: selectedMeter.index },
      });
    }
  };

  return (
    <Container
      className="search-box d-flex align-items-center justify-content-center"
      onKeyDown={handleKeyDown} // To be able to press enter
    >
      {/* Show parameters only if searchType is "position */}
      {searchType === "position" && (
        <>
          {/* Bandel Dropdown */}
          <select
            value={bdl}
            onChange={handleBdlChange}
            className="form-select custom-select me-2"
            style={{
              backgroundColor: "#272a37",
            }}
          >
            <option value="">bdl</option>
            {Object.keys(availableBandels).map((bandel) => (
              <option key={bandel} value={bandel}>
                {bandel}
              </option>
            ))}
          </select>

          {/* Kilometer Dropdown */}
          <select
            value={km}
            onChange={handleKmChange}
            disabled={!bdl}
            className="form-select custom-select me-2"
            style={{
              backgroundColor: "#272a37",
            }}
          >
            <option value="">{bdl ? "km" : ""}</option>
            {availableKms.map((km) => (
              <option key={km} value={km}>
                {km}
              </option>
            ))}
          </select>

          {/* Meter Dropdown */}
          <select
            value={m}
            onChange={handleMChange}
            disabled={!km}
            className="form-select custom-select me-2"
            style={{
              backgroundColor: "#272a37",
            }}
          >
            <option value="">{km ? "m" : ""}</option>
            {availableMeters.map(({ meter, index }) => (
              <option key={index} value={meter}>
                {meter}
              </option>
            ))}
          </select>
          {/* Search Button */}
          <button className="search-btn" type="" onClick={handleSearch}>
            <img src={searchIcon} alt="Search" className="search" />
          </button>
        </>
      )}
      {/* Dropdown to select Position or Location */}
      <div className="dropdown-wrapper">
        <select
          value={searchType}
          onChange={handleSearchTypeChange}
          className="form-select custom-select"
          style={{
            backgroundColor: "#272a37",
            color: "white",
            width: "90px",
            borderRadius: "20px",
            margin: "10px",
          }}
        >
          <option value="position">Position</option>
          <option value="place">Location</option>
        </select>
      </div>
    </Container>
  );
};
export default SearchBoxComponent;
