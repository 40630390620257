import React, { useRef, useEffect, useCallback } from "react";
import { useViewer } from "../../viewerContext";

let PSV = window.PhotoSphereViewer;

export default function PanoramaViewer({ url, sensorScanId }) {
  const { viewerState, setViewerState } = useViewer();

  const panoContainerDiv = useRef("pano");
  const panoViewer = useRef(null);
  const panoTour = useRef(null);
  const nodeRef = useRef();

  const getFunc = useCallback(async (nodeId) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/sensorscans/${sensorScanId}/${nodeId}?save_format=jpg`
    );

    var data = await response.json();

    data.panorama = `${url}${nodeId}.jpg`;

    return data;
  }, [sensorScanId, url]);

  const handlePanoArrowClick = useCallback((node_id, node_data) => {
    nodeRef.current = node_id;
    if (node_id !== viewerState.panoId) {
      setViewerState({
        type: "UPDATE_FROM_PANORAMA",
        payload: { id: nodeRef.current },
      });
    }
  }, [viewerState, setViewerState]);

  useEffect(() => {
    if (panoViewer.current) return;

    /**
     * Reference Material
     * https://developers.google.com/streetview/spherical-metadata
     * https://photo-sphere-viewer.js.org/guide/config.html#advanced-options
     */

    panoViewer.current = new PSV.Viewer({
      container: panoContainerDiv.current,
      requestHeaders: {},
      useXmpData: true,
      defaultZoomLvl: 30,
      autorotateSpeed: "5rpm",
      minFov: 10,
      maxFov: 100,
      defaultLong: 0.0,
      defaultLat: 0.0,
      navbar: ["autorotate", "zoom", "move", "caption", "fullscreen"],
      sphereCorrection: {
        pan: 0,
        tilt: 0,
        roll: 0
      },
      plugins: [
        PSV.MarkersPlugin,
        [
          PSV.VirtualTourPlugin,
          {
            positionMode: PSV.VirtualTourPlugin.MODE_GPS,
            dataMode: PSV.VirtualTourPlugin.MODE_SERVER,
            startNodeId: viewerState.panoId,
            preload: true,
            rotateSpeed: false,
            transition: false,
            arrowPosition: "bottom",
            getNode: getFunc,
            arrowStyle: {
              color: 0xffffff,
              hoverColor: 0xaa5500,
              outlineColor: 0x000000,
              scale: [0.5, 0.75],
            },
          },
        ],
      ],
    });

    panoTour.current = panoViewer.current.getPlugin(PSV.VirtualTourPlugin);

    panoTour.current.on("node-changed", (e, node_id_of_arrow, node_data) =>
      handlePanoArrowClick(node_id_of_arrow, node_data)
    );

    panoTour.current.setCurrentNode(viewerState.panoId);
  }, [getFunc, handlePanoArrowClick, viewerState]);

  useEffect(() => {
    panoTour.current.setCurrentNode(viewerState.panoId);
  }, [viewerState.panoId])

  return (
    <div id="psv-root" className={"psv_container"}>
      <div
        id="psv_render_area"
        ref={panoContainerDiv}
        style={{ height: "100vh" }}
      />
    </div>
  );
}
