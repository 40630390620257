import { useEffect, useRef, useCallback } from "react";
import { MutableRefObject } from "react";
import { useViewer } from "../../viewerContext";
import { round_sig2 } from "../../utilities/helper_math";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import { Point } from "geojson";

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

export default function MapViewer(props: any) {
  const { viewerState, setViewerState } = useViewer();

  const mapContainer = useRef<any>(null);
  const map = useRef() as MutableRefObject<mapboxgl.Map>;
  const localZoom = parseFloat(localStorage.getItem("ZoomLevel") || "20");

  const fetchMapData = useCallback(() => {
    map.current.addControl(new mapboxgl.NavigationControl(), "bottom-right");

    map.current.on("load", () => {
      map.current.loadImage(
        "/img/marker-icon-circle-15px-blue.png",
        function (error: any, image: any) {
          if (error) throw error;
          map.current.addImage("inactive-marker", image);
        }
      );

      map.current.loadImage(
        "/img/marker-icon-20px-red.png",
        function (error: any, image: any) {
          if (error) throw error;
          map.current.addImage("active-marker", image);
        }
      );

      var popup: mapboxgl.Popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      });

      map.current.on(
        "mouseenter",
        "points",
        (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
          let coordinate = e.features[0].geometry.coordinates.slice();
          let e_attr = JSON.parse(e.features[0].properties.attributes);

          while (Math.abs(e.lngLat.lng - coordinate[0]) > 180) {
            coordinate[0] += e.lngLat.lng > coordinate[0] ? 360 : -360;
          }
          popup
            .setLngLat(coordinate)
            .setHTML(
              `<p style="color:black">RailPos: [${e_attr[4]} km + ${round_sig2(
                parseFloat(e_attr[5])
              )} m]<br />Bandel: ${e_attr[6]}</p>`
            )
            .addTo(map.current);
        }
      );

      map.current.on("mouseleave", "points", () => {
        popup.remove();
      });

      map.current.on("zoom", (e) => {
        localStorage.setItem(
          "ZoomLevel",
          JSON.stringify(map.current.getZoom())
        );
      });

      map.current.on(
        "click",
        "points",
        (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
          let e_samples = JSON.parse(e.features[0].properties.samples);
          let e_id: number = e.features[0].id;

          function findpano(item: any) {
            if (item.type === "360_CAMERA") e_id = item.id;
          }

          e_samples.forEach(findpano);

          map.current.flyTo({
            center: e.lngLat,
          });
          setViewerState({
            type: "UPDATE_FROM_MAP",
            payload: { id_map: e.features[0].id, id_pano: e_id },
          });
        }
      );

      map.current.addSource("activePointSource", {
        type: "geojson",
        data: viewerState.data?.features[viewerState.dataId],
        promoteId: "id",
      });

      map.current.addSource("session", {
        type: "geojson",
        data: viewerState.data,
        promoteId: "id",
      });

      map.current.addLayer({
        id: "points",
        type: "symbol",
        source: "session",
        layout: {
          "icon-image": "inactive-marker",
        },
      });

      map.current.addLayer({
        id: "activePoint",
        type: "symbol",
        source: "activePointSource",
        layout: {
          "icon-allow-overlap": true,
          "icon-image": "active-marker",
        },
      });
    });
  }, [viewerState, setViewerState]);

  useEffect(() => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: props.style, // Uses props.style passed from MainViewer
      center: [
        (viewerState.data?.features[viewerState.mapId].geometry as Point)
          .coordinates[0],
        (viewerState.data?.features[viewerState.mapId].geometry as Point)
          .coordinates[1],
      ],
      zoom: localZoom,
      accessToken:
        "pk.eyJ1IjoiYXRyaXRlY3Jhc211cyIsImEiOiJja25veW1nOWMxazE1Mm9ueHhlb2hyY2xzIn0.vVkapGT_NIPM0oWx5-AtQQ",
    });
    fetchMapData();
    return () => {
      map.current.remove();
    };
  }, [props.style, fetchMapData, viewerState, localZoom]);


  return (
    <div id="mapbox_render_area" ref={mapContainer} style={props.mapStyle} />
  );
}
